<template>
  <v-app>
    <v-main>
      <v-card id="images" @click="goApp">
        <img id="frame" src="/frame.jpeg" alt="frame.jpg" />
        <img id="tim" :src="currentImagePath" :alt="currentImagePath" />
      </v-card>
      <!-- <v-btn outlined color="primary" dark >App!</v-btn> -->
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    // HelloWorld,
  },

  data: () => ({
    imagePaths: [
      "/tim/10.JPG",
      "/tim/11.JPG",
      "/tim/12.JPG",
      "/tim/13.JPG",
      "/tim/14.JPG",
      "/tim/15.JPG",
      "/tim/16.JPG",
      "/tim/1.JPG",
      "/tim/2.JPG",
      "/tim/3.JPG",
      "/tim/4.JPG",
      "/tim/5.JPG",
      "/tim/6.JPG",
      "/tim/7.JPG",
      "/tim/8.JPG",
      "/tim/9.JPG",
    ],
    currentImagePath: "/tim/9.JPG",
  }),

  methods: {
    playAppSound: function() {
      let audio = new Audio("/app.mp3");
      audio.play();
    },
    changeImage: function(imagePath) {
      this.currentImagePath = imagePath;
    },
    chooseRandomImage: function() {
      let count = this.imagePaths.length;
      let randomPosition = Math.floor(Math.random() * count);
      return this.imagePaths[randomPosition];
    },
    goApp: function() {
      this.playAppSound();
      this.currentImagePath = this.chooseRandomImage();
      // this.changeImage();
    },
  },
};
</script>

<style scoped>
.v-main {
  background-color: white !important;
}

div#images {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

img#tim {
  transform-origin: 0% 0%;
  transform: matrix(0.5622, -0.048, 0.0351, 0.5699, 0, 0);
  /* transform: matrix(0.5568, -0.0509, 0.0325, 0.5635, 0, 0); */
  left: 12%;
  top: 13%;
  /* transform: matrix(0.6, 0, 0, 0.6, 0, 0); */
  /* transform: matrix(0.55, 0.02, 140, -0.05, 0.5, 152); */
}
</style>
